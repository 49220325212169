import React, { useEffect, useState } from 'react'
import phone from '../../assets/storage/homescreen/icons/Icon-Phone.svg';
import Logo from '../../assets/storage/logo/footerlogo.png';
import send from '../../assets/storage/homescreen/icons/icon-feather-send.svg';
import mail from '../../assets/storage/homescreen/icons/Icon-Mail.svg';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import apiUrl from '../../config';
const Footer = () => {

    const [email,setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [success,setSuccess] = useState('');
    const [info, setInfo] = useState({});
    const [socialMedias, setSocialMedias] = useState([]);
    const location = useLocation();
    const path = location.pathname;
    const firstIndexPath = path?.split('/')[1];

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

    useEffect(() => {
        const fetchInfo = async () => {

            try {
              const res = await axios.get(`${apiUrl}/api/home/info`);
              const filteredInfo = res.data.find(item => item.slug === firstIndexPath);
              setInfo(filteredInfo);
            } catch (error) {
              console.log(error);
            }
          };
          fetchInfo();

     },[firstIndexPath]);

     useEffect(() => {
        const fetchIcons = async () => {
            const res = await axios.get(`${apiUrl}/api/social-media`)
            setSocialMedias(res?.data);
        }
        fetchIcons()
    },[])

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            setEmailError('Email is required.');
            setTimeout(() => {
                setEmailError('');
            }, 3000);
            return;
        } else if (!isValidEmail(email)) {
            setEmailError('Invalid Email Format.');
            setTimeout(() => {
                setEmailError('');
            }, 3000); 
            return;
        }

        setEmailError('');

        try {
            const response = await axios.post(`${apiUrl}/api/news_letter`, { email });

            setSuccess(response.data);
            setTimeout(() => {
                setSuccess('');
            }, 3000);

            setEmail('');
        } catch (error) {
            console.log(error)
        }
      
    }


    const isValidEmail = (email) => {
        const emailRegex = /^\S+@\S+\.\S+$/;
        return emailRegex.test(email);
    };

  return (
    <>
        <footer>
            {info && (
                <div className='position-relative'>
                   <div className='bg-prime-op'></div>
                   <div className='w-60 mx-auto contact-container py-4 container-sm'>
                       <div className='row align-items-center g-1'>
                           <div className='col-lg-4 col-sm-3 text-center text-md-start'>
                               <span className='text-prime fs-25 fw-bold'>{info?.title}</span>
                           </div>
                           <div className='col-lg-4 col-sm-4'>
                                {info?.phone && (
                                    <div className='d-flex align-items-center justify-content-center justify-content-md-start g-1'>
                                        <img className="w-auto" src={phone} alt="phone" />
                                        <a className='text-decoration-none pointer' href={`tel:${info?.phone}`}>
                                            <span className='text-prime fs-25 pointer'>{info?.phone}</span>
                                        </a>
                                    </div>
                                )}    
                           </div>
                           <div className='col-lg-3 col-sm-4 '>
                               <div className='d-flex align-items-center justify-content-center justify-content-md-start g-1'>
                                   <img className="w-auto" src={mail} alt="mail" />
                                   <a className='text-decoration-none pointer' href="mailto:tablegames@cdl.com.lb">
                                       <span className='text-prime fs-25 pointer'>{info?.email}</span>
                                   </a>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
            )}
         
            <div className='bg-second'>
                <div className='container py-5'>
                    <div className='row justify-content-start'>
                        <div className="col-12 col-md-12 col-lg-3 text-md-start me-sm-4 me-md-0">
                            <div className='footer-logo mb-3 mb-sm-0'>
                                <Link to={'/'} className='pointer' onClick={scrollToTop}>
                                    <img src={Logo} alt="logo" />
                                </Link>  
                            </div>
                        </div>
                        <div className='col-sm-3 col-md-2 col-lg-2 my-2 text-md-start'>
                            <div className='d-flex flex-column text-white g-1 fw-bold mb-2'>
                                    <span className='footer-links' onClick={scrollToTop}><Link to={'/about-us'}>About Us</Link></span>
                                    <span className='footer-links' onClick={scrollToTop}><Link to={'/cdl-rewards'}>CDL Rewards</Link></span>  
                                    <span className='footer-links' onClick={scrollToTop}><Link to={'/contact-us'}>Contact us</Link></span>      
                            </div>
                        </div>
                        <div className='col-sm-3 col-md-2 col-lg-2 my-2 text-md-start'>
                            <div className='d-flex flex-column text-white g-1 fw-bold'>
                                    <span className='footer-links' onClick={scrollToTop}><Link to={'/rules-&-admissions'}>Rules & Admission</Link></span>
                                    <span className='footer-links' onClick={scrollToTop}><Link to={'/responsible-gaming'}>Responsible Gaming</Link></span>     
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-2 col-lg-2 my-2 text-md-start">
                            <div className='d-flex flex-column text-white g-1 fw-bold'>
                                    <span className='footer-links' onClick={scrollToTop}><Link to={'/faq'}>FAQ</Link></span>
                                    <span className='footer-links' onClick={scrollToTop}><Link to={'/gallery'}>Gallery</Link></span>
                            </div>
                        </div>
                        <div className="col-9 col-sm-6 col-md-5 col-lg-3 my-2 text-start w-sm-100">
                            <div className='d-flex flex-column text-white g-1 fw-bold news-letter'>
                                    <span className="text-white text-md-start fw-bold text-uppercase">Subscribe to our newsletter</span>
                                    <form onSubmit={handleSubmit}>
                                        <div className='d-flex align-items-center input'>
                                            <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} className="w-100 py-2 px-3" />
                                            <button type="submit" style={{ display: 'none' }} />
                                            <div className='bg-prime icon' onClick={handleSubmit}>
                                                <img className='w-auto' src={send} alt="" />
                                            </div>
                                        </div>
                                        {success && <p className="success">{success}</p>}
                                        {emailError && <p className="error">{emailError}</p>}
                                    </form>
                                    <div className='nav__social_links'>
                                    {socialMedias && socialMedias?.map((media,index) => (
                                        <img key={index} className='w-auto pointer' src={media?.second_icon} alt={`${media?.title}`} />
                                    ))}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container py-3'>
                <div className='d-flex flex-column flex-md-row  justify-content-between align-items-center text-prime'>
                    <div onClick={scrollToTop}>
                        <Link to={'/policy'} className="text-decoration-none text-prime">
                            <span className='pe-3'>Terms & Condition</span>
                            <span>Privacy Policy</span>
                        </Link>
                    </div>
                    <div>
                        <span>Design And Developed by <Link to={'https://tedmob.com'} className='text-uppercase text-prime pointer'>Tedmob</Link></span>
                    </div>
                    <div className='text-center'>
                        <span className='fs-16'>Copyright © 2023 Casino Du Liban. All rights reserved.</span>
                    </div>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Footer