
import React, { useState } from 'react'
import moment from 'moment';
import { Link, useParams } from 'react-router-dom'
import StripTags from '../components/StripTags'
import { useEffect } from 'react'
import axios from 'axios'
import apiUrl from '../../config'
const SingleEvent = () => {

    const [event, setEvent] = useState({});
    const [venues, setVenues] = useState([]);
    const [tableEvent,setTableEvent] = useState([]);

    const {id} = useParams();

    useEffect(() => {
        const fetchEvent = async () => {
          const res = await axios.get(`${apiUrl}/api/events/${id}`);
          setVenues(res.data.venues);
          setEvent(res.data.event);
          setTableEvent(res.data.table);
        }
        fetchEvent()
    },[id]) 

  
  return (
    <>
        <div className='events'>
            <div className='navs py-5'>
                <div className='w-60 mx-auto p-4 text-center'>
                    <ul class="nav nav-pills nav-fill justify-content-center flex-md-nowrap mb-3 w-100" id="pills-tab" role="tablist">
                        <li class="nav-item fs-18 w-100" role="presentation">
                            <button class="nav-link active" id="pills-customer-service-tab" data-bs-toggle="pill" data-bs-target="#pills-customer-service" type="button" role="tab" aria-controls="pills-customer-service" aria-selected="true">Events</button>
                        </li>
                        <li class="nav-item fs-18 w-100" role="presentation">
                            <button class="nav-link" id="pills-contact-directory-tab" data-bs-toggle="pill" data-bs-target="#pills-contact-directory" type="button" role="tab" aria-controls="pills-contact-directory" aria-selected="false">Venues</button>
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active w-100" id="pills-customer-service" role="tabpanel" aria-labelledby="pills-customer-service-tab">
                        <div className='w-60 mx-auto px-4'>
                            {event && (
                                <>
                                    <div className='main-title-v2'><span className='h3'>{event?.title}</span></div>
                                    <div className='main-description'>
                                        <p className='text-center fs-18 w-75 mb-5'>
                                            <StripTags policyText={event?.description} />
                                        </p>
                                    </div>

                            <div className='picture-wrapper padding-top-40'>
                                <img src={event?.image} alt='banner-table' />
                            </div>

                            {/* Table Event */}
                            <div className='my-5'>
                                <div className='table-responsive'>
                                    <table className='w-100'>
                                        <thead className="position-relative">
                                            <th className='pt-3 pb-2 px-4 px-lg-0 fs-18  text-center text-white'>Date</th>
                                            <th className='pt-3 pb-2 px-4 px-lg-0 fs-18  text-center text-white'>Venue</th>
                                            <th className='pt-3 pb-2 px-4 px-lg-0 fs-18  text-center text-white'>Time</th>
                                            <th className='pt-3 pb-2 px-4 px-lg-0 fs-18  text-center text-white'>Price</th>
                                            <span className='table-rounded'></span>
                                        </thead>
                                        <tbody>
                                            {tableEvent && tableEvent?.map(({from_date,to_date,price,time,venue},id) => (
                                                <tr key={id}>
                                                    <td className="text-center">
                                                        {to_date === null ? (
                                                            <span>{moment.unix(from_date).format('DD MMM YYYY')}</span>
                                                        ) : (
                                                            <span>From {moment.unix(from_date).format('DD MMM YYYY')} to {moment.unix(to_date).format('DD MMM YYYY')}</span>
                                                        )}
                                                    </td>
                                                    <td className="text-center">
                                                        <span>{venue}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <span>{time}</span>
                                                    </td>
                                                    <td className="text-center" style={{fontSize:'16px'}} >
                                                        <span><StripTags policyText={price}/></span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                            
                            <hr />
                            </div>
                                    {event?.reservation && (
                                        <div className='mt-5'>
                                            <span className="text-black fw-bold fs-20">For Reservation</span>
                                            <p className='text-prime fw-bold fs-20'>{event?.reservation}</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                        {/* Venues */}
                        <div class="tab-pane fade" id="pills-contact-directory" role="tabpanel" aria-labelledby="pills-contact-directory-tab">
                            <div className="w-60 mx-auto px-4">
                                {venues && venues?.map(({title,image,description},index) => (
                                    <div key={index} className="mt-2 spacing-2">
                                    <div className="main-title-v2">
                                        <span className="h3">{title}</span>
                                    </div>
                                    <div className="main-description">
                                        <p className="text-center fs-18 w-75 fs-18 mt-2 mb-4">
                                            <StripTags policyText={description} />
                                        </p>
                                    </div>
                
                                    <div className="picture-wrapper padding-top-40 my-3">
                                        <img src={image} alt="banner-table" />
                                        <span className="venue-span"><Link to={'/events'} className="text-decoration-none text-white">Calendar Of Events</Link></span>
                                    </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SingleEvent