import React, { useState } from "react";
import clock from "../../assets/storage/homescreen/icons/clock.svg";
import locationIcon from "../../assets/storage/homescreen/icons/location.svg";
import searchIcon from '../../assets/storage/homescreen/icons/Search-Icon.svg';
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import moment from 'moment';
import StripTags from "../components/StripTags";
import apiUrl from "../../config";
const Events = () => {

  const [events, setEvents] = useState([]);
  const [venues, setVenues] = useState([]);
  const [filterVenues, setFilterVenues]  = useState([]);
  const [selectedVenue, setSelectedVenue] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [search ,setSearch] = useState('');

  const location = useLocation();
  // const searchTab = location?.search?.split('?tab=')[1];
  const searchTab = location?.search?.split('?tab=')[1]?.split('&')[0];
  const searchTabName = location?.search?.split('&name=')[1];
  const uniqueYears = [...new Set(events?.map(({ date }) => moment(date).format('YYYY')))].sort();
  
  const now = new Date(); 
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  function truncate(string,number)  {
    return string?.length > number ? string.substring(0,number-1)+ "..." : string
  }

  useEffect(() => {
      const fetchEvents = async () => {
        const res = await axios.get(`${apiUrl}/api/events`);
        setVenues(res.data.venues);
        setEvents(res.data.events);
      }
      fetchEvents()

      const fetchFilterVenues = async () => {
        const res = await axios.get(`${apiUrl}/api/events/filter-venues`);
        setFilterVenues(res.data);
      }
      fetchFilterVenues()
  }, [])

  useEffect(() => {
    if(searchTabName !== undefined){
      setSelectedVenue(searchTabName?.replace(/%20/g,' '));
    }
  },[searchTabName])



  return (
    <>
    <div className="container">
        <div className="events">
          <div className="navs mt-5 mb-6">
            <div className="p-4 w-60 mx-auto text-center">
              <ul
                class="nav nav-pills nav-fill justify-content-center flex-md-nowrap mb-3 w-100"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item w-100" role="presentation">
                  <button
                    class={`nav-link fs-18 ${searchTab === 'events' ? 'active' : '' }`}
                    id="pills-customer-service-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-customer-service"
                    type="button"
                    role="tab"
                    aria-controls="pills-customer-service"
                    aria-selected="true"
                  >
                    Events
                  </button>
                </li>
                <li class="nav-item w-100" role="presentation">
                  <button
                    class={`nav-link fs-18 ${searchTab === 'venues' ? 'active' : '' }`}
                    id="pills-contact-directory-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-contact-directory"
                    type="button"
                    role="tab"
                    aria-controls="pills-contact-directory"
                    aria-selected="false"
                  >
                    Venues
                  </button>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
              {/* Events */}
              <div
                class={`tab-pane fade ${searchTab === 'events' ? 'active show' : '' } w-100`}
                id="pills-customer-service"
                role="tabpanel"
                aria-labelledby="pills-customer-service-tab"
              >
                <div className="container">
                  <div className="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between">
                    <div className="d-flex align-items-center g-1">
                      <div class="dropdown v2">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                         {selectedYear ? selectedYear : 'All Years'}
                        </button>
                        <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" href="#" onClick={() => setSelectedYear('')}>All Years</a></li>
                            {uniqueYears && uniqueYears?.map((data,index) => (
                                <li key={index}><a class="dropdown-item" href="#" onClick={() => setSelectedYear(data)}>{data}</a></li>
                            ))}
                        </ul>
                      </div>
                      <div class="dropdown v2">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedVenue ? selectedVenue : 'All Venues'}
                        </button>
                        <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton2">
                          <li><a class="dropdown-item" href="#" onClick={() => setSelectedVenue('')}>All Venues</a></li>
                            {filterVenues?.map(({title},index) => (
                                  <li key={index}><a class="dropdown-item" href="#" onClick={() => setSelectedVenue(title)}>{title}</a></li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div className="search-input mt-3 mt-sm-0">
                      <img className="w-auto" src={searchIcon} alt="search" />
                      <input type="text" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)}  />
                    </div>
                  </div>

                  <div className="row my-sm-5 my-2">
                    {events && events
                     .filter((item) => {
                        const eventDate = new Date(item.date);
                        const isUpcomingEvent = eventDate >= today;
                        const venueMatch = item.venue.title.toLowerCase().includes(selectedVenue.toLowerCase());
                        const titleMatch = item.title.toLowerCase().includes(search.toLowerCase());
                        const dateMatch = item.date.includes(selectedYear); 

                        const hasAnyFilters = selectedYear || selectedVenue || search;

                        if (!hasAnyFilters) {
                          return isUpcomingEvent;
                        }

                        return venueMatch && dateMatch && titleMatch;
                      })
                    .map(({date,description,image,title,venue,id},index) => (
                        <div key={index} className="col-lg-4 my-3">
                        <div className="event-card">
                          <div className='picture-wrapper padding-top-55'>
                            <img src={image} alt="" />
                          </div>
                          <div className="my-sm-3 my-2">
                            <span className="fs-32 text-prime font-times">{title}</span>
                            <div className="d-flex align-items-center gap-5 mt-2">
                              <div className="d-flex align-items-center">
                                <img className="w-20" src={clock} alt="" />
                                <span className="ms-2 fs-18 w-100 text-prime fw-bold">
                                  {date}
                                </span>
                              </div>
                              <div className="d-flex align-items-center">
                                <img className="w-auto" src={locationIcon} alt="" />
                                <span className="ms-2 fs-18 w-100 text-prime fw-bold">
                                  {venue?.title}
                                </span>
                              </div>
                            </div>
                            <p className="my-2 fs-18">
                              <StripTags policyText={truncate(description,150)} />
                            </p>
                            <span className="link__events fs-16">
                              <Link to={`/events/${id}`} className="fs-16" href="#">READ MORE</Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Venues */}
              <div
                class={`tab-pane fade ${searchTab === 'venues' ? 'active show' : '' }`}
                id="pills-contact-directory"
                role="tabpanel"
                aria-labelledby="pills-contact-directory-tab"
              >
                <div className="w-60 mx-auto px-4">
                  {venues && venues?.map(({title,image,description},index) => (
                      <div key={index} className="mt-2 spacing-2">
                      <div className="main-title-v2">
                        <span className="h3">{title}</span>
                      </div>
                      <div className="main-description">
                        <p className="text-center fs-18 w-75 fs-18 mt-2 mb-4">
                            <StripTags policyText={description} />
                        </p>
                      </div>
  
                      <div className="picture-wrapper padding-top-40 my-3">
                        <img src={image} alt="banner-table" />
                        <span className="venue-span"><a href={`/events?tab=events&name=${title}`} className="text-decoration-none text-white">Calendar Of Events</a></span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
