import React, { useState } from 'react'
import clock from '../../assets/storage/homescreen/icons/clock.svg'
import access from '../../assets/storage/homescreen/icons/group-301.svg'
import dress from '../../assets/storage/homescreen/icons/group-300.svg'
import asterix from '../../assets/storage/homescreen/icons/polygon-15.svg'
import ticketsBanner from '../../assets/storage/slots/mask-group-4.png'
import { useEffect } from 'react'
import axios from 'axios'
import StripTags from '../components/StripTags'
import apiUrl from '../../config'
import { useLocation } from 'react-router-dom'
const Slots = () => {

    const [data, setData] = useState({});
    const [table,setTable] = useState([]);
    const [categories, setCategories] = useState([]);
    const [tickets,setTickets] = useState([]);

    const location = useLocation();
    const searchTab = location?.search?.split('?tab=')[1];


    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`${apiUrl}/api/slots`);
            setData(res.data.slots);
            setTable(res.data.table);
            setCategories(res.data.categories);
        }
        fetchData()

        const fetchTickets = async () => {
            const res = await axios.get(`${apiUrl}/api/slots/tickets_and_payments`);
            setTickets(res.data);
        }
        fetchTickets()
    },[])
 

  return (
    <>
    <div className='container'>
        <div className='slots'>
            <div className='navs py-5'>
                <div className='w-60 mx-auto'>
                    <ul class="nav nav-pills nav-fill justify-content-center flex-md-nowrap mb-3 w-100" id="pills-tab" role="tablist">
                        <li class="nav-item br-prime w-100" role="presentation">
                            <button class={`nav-link fs-18 ${searchTab === 'slots-palace' ? 'active' : ''}`} id="pills-customer-service-tab" data-bs-toggle="pill" data-bs-target="#pills-customer-service" type="button" role="tab" aria-controls="pills-customer-service" aria-selected="true">Slots Palace</button>
                        </li>
                        <li class="nav-item bl-prime w-100" role="presentation">
                            <button class={`nav-link fs-18 ${searchTab === 'tickets-&-payments' ? 'active' : ''}`} id="pills-contact-directory-tab" data-bs-toggle="pill" data-bs-target="#pills-contact-directory" type="button" role="tab" aria-controls="pills-contact-directory" aria-selected="false">Tickets & Payments</button>
                        </li>
                    </ul>
                </div>
                <div class="tab-content" id="pills-tabContent">
                    {/* Slots Palace */}
                    <div class={`tab-pane fade ${searchTab === 'slots-palace' ? 'show active' : ''} w-100`} id="pills-customer-service" role="tabpanel" aria-labelledby="pills-customer-service-tab">
                        <div className='w-60 mx-auto p-4'>
                        <div className='main-title'><span className='h3'>Slots Palace</span></div>
                        {data && (
                            <>
                                <div className='main-description'>
                                    <p className='text-center fs-18 w-75'>
                                        <StripTags policyText={data?.first_text} />
                                    </p>
                                </div>
    
                                   
                                <div className='d-flex justify-content-center gap-sm-5 my-5'>
                                    <div className='d-flex flex-column flex-sm-row align-items-center'>
                                        <img className='pt-4 width-25' src={clock} alt="clock" />
                                        <div className='d-flex flex-column ps-sm-3'>
                                            <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Opening Hours</span>
                                            <span className='text-prime fs-18 text-center text-sm-start'><StripTags policyText={data?.opening_hour} /></span>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column flex-sm-row align-items-center'>
                                        <img className='pt-4 width-25' src={access} alt="clock" />
                                        <div className='d-flex flex-column mx-4 mx-sm-0 ps-sm-3'>
                                            <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Access</span>
                                            <span className='text-prime fs-18 text-center text-sm-start'>No Membership Required</span>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column flex-sm-row align-items-center'>
                                        <img className='pt-4 width-25' src={dress} alt="clock" />
                                        <div className='d-flex flex-column ps-sm-3'>
                                            <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Dress Code</span>
                                            <span className='text-prime fs-18 text-center text-sm-start'><StripTags policyText={data?.dress_code} /></span>
                                        </div>
                                    </div>
                                </div>
    
                                <div className='picture-wrapper padding-top-40'>
                                    <img src={data?.first_image} alt='banner-table' />
                                </div>
                                </>
                        )}
                    
                            <div className='main-title-v2 mt-5 mb-3'><span className='h3'>What’s on the Floor</span></div>
                        </div>
                        {/* Table Slots */}
                        {categories && categories?.map(({title,image,id},index) => (
                            <div key={index} className='w-75 w-sm-100 mx-auto mb-5'>
                             <div className='table-responsive'>
                                    <table className='w-100 table-border '>
                                        <thead className="position-relative">
                                            <th className='pt-3 pb-2 px-4 px-lg-0 fs-18 text-center text-white'>{title}</th>
                                            <th className='pt-3 pb-2 px-4 px-lg-0 fs-18 text-center text-white'>Denomination in LBP</th>
                                            <th className='pt-3 pb-2 px-4 px-lg-0 fs-18 text-center text-white'>Number of Machines</th>
                                            <th className='pt-3 pb-2 px-4 px-lg-0 fs-18 text-center text-white'>Lines of Play</th>
                                            <th className='pt-3 pb-2 px-4 px-lg-0 fs-18 text-center text-white'>Minimum Level of Bets</th>
                                            <th className='pt-3 pb-2 px-4 px-lg-0 fs-18 text-center text-white'>Maximum Level of Bets</th>
                                            <span className='table-rounded'></span>
                                        </thead>
                                        <tbody style={{zIndex: '-2', position: 'relative'}}>
                                            <tr>
                                                <td rowSpan={10} className="text-center" style={{ width : '100px', height: '300px'}}>
                                                    <img className='w-auto' src={image} alt="table-image" />
                                                </td>
                                            </tr>
                                            {table && table?.filter((data) => data?.slot_category_id == id).map((col,index) => (
                                                <tr key={index}>
                                                    <td className="text-center">
                                                        <span>{col?.domination_in_lbp}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <span>{col?.number_of_machines}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <span>{col?.lines_of_play}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <span>{col?.minimum_level_of_bets}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <span>{col?.maximum_level_of_bets}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}

                        <div className='w-60 mx-auto p-4'>
                            <div className='main-title'><span className='h3'>Slots Bar</span></div>
                            {data && (
                                <>
                                <div className='main-description mb-2'>
                                    <p className='text-center w-75 mb-4 fs-18'>
                                        <StripTags policyText={data?.second_text} />
                                    </p>
                                </div>
                                <div className='picture-wrapper padding-top-40'>
                                    <img src={data?.second_image} alt='banner-table' />
                                </div>
                             </>
                            )}
                        </div>
                    </div>

                    {/* Tickets & Payments */}
                    <div class={`tab-pane fade ${searchTab === 'tickets-&-payments' ? 'show active' : ''}`}  id="pills-contact-directory" role="tabpanel" aria-labelledby="pills-contact-directory-tab">
                            <div className='table-games w-60 mx-auto p-4'>
                                <div className='main-title'><span className='h3'>Tickets & Payments</span></div>

                                <div className='row my-4'>
                                    <div className='col-lg-5 col-12'>
                                        {tickets && tickets?.map(({description},index) => (
                                            <div className='d-flex align-items-center g-1 mb-3'>
                                                <img className='w-auto mb-2' src={asterix} alt="asterix" />
                                                <p className='fs-18'><StripTags policyText={description} /></p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='col-10 col-lg-6'>
                                        <div className='picture-wrapper padding-top-141 ms-lg-2'>
                                            <img className='w-auto img-2' src={ticketsBanner} alt='tickets'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Slots