import React, { useState } from 'react'
import Logo from '../../assets/storage/logo/logo.png';
import asterix from '../../assets/storage/homescreen/icons/polygon-11.png';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import apiUrl from '../../config';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [socialMedias, setSocialMedias] = useState([]);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
      };
      
    useEffect(() => {
        const fetchIcons = async () => {
            const res = await axios.get(`${apiUrl}/api/social-media`)
            setSocialMedias(res?.data);
        }
        fetchIcons()
    },[])
    
  return (
    <>
        <header>
            {/* NavBar Top */}
            <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container pt-lg-4">
                <a class="navbar-brand" href="#">
                    <div className='nav__left'>
                        <Link to={'/'}>
                            <img src={Logo} alt='Casino-Logo' />
                        </Link>
                    </div>
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" onClick={toggleMenu} data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
                    <div className='nav__right'>
                        <div className="nav__links">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={toggleMenu}  to={'/about-us'}>About Us</Link>
                                    <span><img className='w-auto d-none d-lg-block' src={asterix} alt="asterix" /></span>
                                </li>
                                {/* <li class="nav-item">
                                    <Link class="nav-link" onClick={toggleMenu}  to={'/travel-to-lebanon'}>Travel To Lebanon</Link>
                                    <span><img className='w-auto d-none d-lg-block' src={asterix} alt="asterix" /></span>
                                </li> */}
                                {/* <li class="nav-item">
                                    <Link class="nav-link" onClick={toggleMenu} to={'/news'}>News</Link>
                                    <span><img className='w-auto d-none d-lg-block' src={asterix} alt="asterix" /></span>
                                </li> */}
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={toggleMenu} to={'/gallery'}>Gallery</Link>
                                    <span><img className='w-auto d-none d-lg-block' src={asterix} alt="asterix" /></span>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={toggleMenu} to={'/contact-us'}>Contact us</Link>
                                </li>
                            </ul>

                            <ul class="navbar-nav d-lg-none d-block">
                                <li class="nav-item">
                                    <Link class="nav-link active" onClick={toggleMenu} aria-current="page" to={'/cdl-rewards'}>CDL Rewards</Link>
                                    <span><img className='w-auto d-none d-lg-block' src={asterix} alt="asterix" /></span>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={toggleMenu} aria-current="page" to={'/table-games?tab=rooms'}>Table Games</Link>
                                    <span><img className='w-auto d-none d-lg-block' src={asterix} alt="asterix" /></span>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={toggleMenu}  to={'/slots-palace?tab=slots-palace'}>Slots</Link>
                                    <span><img className='w-auto d-none d-lg-block' src={asterix} alt="asterix" /></span>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={toggleMenu}  to={'/poker?tab=calendar-of-events'}>Poker</Link>
                                    <span><img className='w-auto d-none d-lg-block' src={asterix} alt="asterix" /></span>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={toggleMenu} to={'/events?tab=events'}>Events & Entertainment</Link>
                                    <span><img className='w-auto d-none d-lg-block' src={asterix} alt="asterix" /></span>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" onClick={toggleMenu} to={'/dining?tab=casino-restaurants'}>Dining & Nightlife</Link>
                                    <span><img className='w-auto d-none d-lg-block' src={asterix} alt="asterix" /></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='nav__social_links d-none d-lg-flex'>
                    {socialMedias && socialMedias?.map((media,index) => (
                        <img key={index} className='w-auto pointer' src={media?.icon} alt={`${media?.title}`} />
                    ))}
                </div>
            </div>
            </nav>
            
            {/* NavBar Bottom */}
            <div className='bg-prime d-lg-block d-none'>
                <div className='container d-flex py-2'>
                    <div style={{'width': 'clamp(200px,14.43vw,277px)'}}></div>
                    <div class="nav__bottom d-flex">
                    <div class="dropdown">
                        <a class="btn dropdown-toggle border-0 px-0 ps-1" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Table Games
                        </a>
                        <ul class="dropdown-menu bg-transparent border-0 text-center">
                            <li className='list rounded '><Link class="dropdown-item p-0 py-2 w-100" to={'/table-games?tab=rooms'}>Rooms</Link></li>
                            <li className='list rounded'><Link class="dropdown-item p-0 py-2 w-100" to={'/table-games?tab=games'}>Games</Link></li>
                        </ul>
                    </div>
                    <div class="dropdown">
                        <a class="btn dropdown-toggle border-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Slots
                        </a>
                        <ul class="dropdown-menu bg-transparent border-0 text-center">
                            <li className='list rounded '><Link class="dropdown-item p-0 py-2 w-100" to={'/slots-palace?tab=slots-palace'}>Slots Palace</Link></li>
                            <li className='list rounded'><Link class="dropdown-item p-0 py-2 w-100" to={'/slots-palace?tab=tickets-&-payments'}>Tickets & Payments</Link></li>
                        </ul>
                    </div>
                    <div class="dropdown">
                        <a class="btn dropdown-toggle border-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Poker
                        </a>
                        <ul class="dropdown-menu bg-transparent border-0 text-center">
                        <li className='list rounded '><Link class="dropdown-item p-0 py-2 w-100" to={'/poker?tab=calendar-of-events'}>Calendar Of Events</Link></li>
                            {/* <li className='list rounded'><Link class="dropdown-item p-0 py-2 w-100" to={'/poker?tab=special-tournaments'}>Special Tournaments</Link></li> */}
                            <li className='list rounded'><Link class="dropdown-item p-0 py-2 w-100" to={'/poker?tab=cash-games'}>Cash Games</Link></li>
                            <li className='list rounded '><Link class="dropdown-item p-0 py-2 w-100" to={'/poker?tab=blinds-&-prize'}>Blinds & Prize Structure</Link></li>
                            <li className='list rounded'><Link class="dropdown-item p-0 py-2 w-100" to={'/poker?tab=results'}>Results</Link></li>
                        </ul>
                    </div>
                    <div class="dropdown">
                        <a class="btn dropdown-toggle border-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Events & Entertainment
                        </a>
                        <ul class="dropdown-menu bg-transparent border-0 text-center">
                            <li className='list rounded '><Link class="dropdown-item p-0 py-2 w-100" to={'/events?tab=events'}>Events</Link></li>
                            <li className='list rounded'><Link class="dropdown-item p-0 py-2 w-100" to={'/events?tab=venues'}>Venues</Link></li>
                        </ul>
                    </div>
                    <div class="dropdown">
                        <a class="btn dropdown-toggle border-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Dining & Nightlife
                        </a>
                        <ul class="dropdown-menu bg-transparent border-0 text-center">
                        <li className='list rounded '><Link class="dropdown-item p-0 py-2 w-100" to={'/dining?tab=casino-restaurants'}>Casino Restaurants</Link></li>
                            <li className='list rounded'><Link class="dropdown-item p-0 py-2 w-100" to={'/dining?tab=la-martingale-restaurant'}>La Martingale Restaurant</Link></li>
                            <li className='list rounded'><Link class="dropdown-item p-0 py-2 w-100" to={'/dining?tab=la-martingale-terrase'}>La Martingale Terrase</Link></li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </header>
    </>
  )
}

export default Navbar