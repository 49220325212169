import React, { useState } from 'react'
import clock from '../../assets/storage/homescreen/icons/clock.svg'
import access from '../../assets/storage/homescreen/icons/group-301.svg'
import dress from '../../assets/storage/homescreen/icons/group-300.svg'
import banner from '../../assets/storage/table-games/dsc-2290.png'
import { useEffect } from 'react'
import axios from 'axios'
import StripTags from '../components/StripTags'
import apiUrl from '../../config'
import { Link, useLocation } from 'react-router-dom'

const TableGames = () => {

    const [games,setGames] = useState([]);
    const [rooms,setRooms] = useState([]);
    const [tableGames, setTableGames] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState('INTERNATIONAL ROOM'.replace(/\s+/g, ''));
    const location = useLocation();
    const searchTab = location?.search?.split('?tab=')[1];

    useEffect(() => {
        const fetchRooms = async () => {
            const res = await axios.get(`${apiUrl}/api/tables/rooms`);
            setRooms(res.data);
        } 
        const fetchGames = async () => {
            const res = await axios.get(`${apiUrl}/api/tables/games`);
            setGames(res.data.games);
            setTableGames(res.data.tables);
        } 
        fetchRooms();
        fetchGames();
    },[selectedRoom]);

  return (
    <>
    <div className='table-games'>
            <div class="mobile-title">TABLE GAMES</div>
            <div className='navs'>
            <div className='w-60 mx-auto p-4'>
                <ul class="nav nav-pills nav-fill justify-content-center flex-md-nowrap mb-3 w-100 table-pill">
                    <li class="nav-item dropdown w-100 br-prime">
                        <a class={`nav-link dropdown-toggle text-capitalize fs-20 left ${searchTab === 'rooms' ? 'active' : ''}`}  href="#rooms" data-bs-toggle="dropdown">Rooms</a>
                        <ul class="dropdown-menu w-100 p-0 room">
                        {rooms && rooms?.map((data,index) => (
                            <li key={index}><a class={`dropdown-item text-center w-100 fs-18 ${data?.title.replace(/\s+/g, '') === selectedRoom ? 'active' : '' }`} style={{borderRadius: '4px'}} onClick={() => setSelectedRoom(data?.title.replace(/\s+/g, ''))} data-bs-toggle="pill" aria-expanded="false" href="#1">{data?.title}</a></li>
                        ))}
                        </ul>
                    </li>
                    <li class="nav-item w-100 bl-prime">
                        <a class={`nav-link right ${searchTab === 'games' ? 'active' : ''} w-100 fs-18`} data-bs-toggle="pill" href="#games">Games</a>
                    </li>
                </ul>
            </div>
                <div class="tab-content" id="pills-tabContent">
                    <div class={`tab-pane w-100 ${searchTab === 'rooms' ? 'active show' : ''} fade`} id="1" role="tab" aria-labelledby="pills-customer-service-tab">
                    <div className='w-60 mx-auto p-4'>
                        {rooms && rooms?.filter((data) => data?.title.replace(/\s+/g, '') === selectedRoom )
                        .map((data,index) => (
                            <div key={index}>
                                <div className='main-title '><span className='h3'>{data?.title}</span></div>
                                <div className='main-description'>
                                    <p className='text-center fs-18 w-75'>
                                        <StripTags policyText={data?.text} />
                                    </p>
                                </div>
        
                                   
                                <div className='d-flex justify-content-center gap-sm-5 my-5'>
                                    <div className='d-flex flex-column flex-sm-row align-items-center'>
                                        <img className='pt-4 width-25' src={clock} alt="clock" />
                                        <div className='d-flex flex-column ps-sm-3'>
                                            <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Opening Hours</span>
                                            <span className='text-prime fs-18 text-center text-sm-start'><StripTags policyText={data?.opening_hour} /></span>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column flex-sm-row align-items-center'>
                                        <img className='pt-4 width-25' src={access} alt="clock" />
                                        <div className='d-flex flex-column mx-4 mx-sm-0 ps-sm-3'>
                                            <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Access</span>
                                            <span className='text-prime fs-18 text-center text-sm-start'>Membership Required</span>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column flex-sm-row align-items-center'>
                                        <img className='pt-4 width-25' src={dress} alt="clock" />
                                        <div className='d-flex flex-column ps-sm-3'>
                                            <span className='fs-18 fw-bold text-center text-prime text-sm-start'>Dress Code</span>
                                            <span className='text-prime fs-18 text-center text-sm-start'><StripTags policyText={data?.dress_code} /></span>
                                        </div>
                                    </div>
                                </div>
        
                                <div className='picture-wrapper padding-top-40'>
                                    <img src={data?.image} alt='banner-table' />
                                </div>
                            </div>
                        ))}
                     
                        <div className='main-title mt-5'><span className='h3'>Available Games</span></div>
                    </div>
                    <div className='container'>
                        <div className='row my-3'>
                        {games
                        ?.map((data) => data?.room
                        ?.filter((x) => x?.title?.replace(/\s+/g, '') === selectedRoom)
                        ?.map((roomData,index) => {
                            const filteredTableGames = tableGames.filter(
                            (game) => game?.game_id == data?.id && game?.room_id == roomData?.pivot?.room_id
                        );  
                            return (
                                <div key={index} className='col-lg-4  col-sm-6 col-xxl-3 my-4'>
                                <div>
                                    <div className='card-table-games picture-wrapper padding-top-80'>
                                        <div className='text-center top-title'>
                                            <span className='fw-bold text-prime text-uppercase'>{data?.title}</span>
                                        </div>
                                        <div className='text-center left-title'>
                                            <Link className='btn-3' to={data?.link}>Rules of the game</Link>
                                        </div>
                                        <div><img src={data?.image} alt="roulette" /></div>
                                    </div>
                                    {filteredTableGames?.map((table,index) => (
                                            <div key={index} className='card-table-info text-center py-4 w-100'>
                                            <div className='row w-75 ms-2'>
                                                <div className='col-lg-12 mb-3 text-start'>
                                                    <span className='fs-16'>
                                                        Minimum Levels Of Bets
                                                    </span>
                                                </div>
                                                <div className='col-lg-12 mb-3 text-start'>
                                                    <span className='fs-16'>
                                                        Maximum Levels Of Bets
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='row w-50 ms-2'>
                                                <div className='col-lg-12 mb-3 text-start'>
                                                    <span className='fw-bold fs-16 '>
                                                        {table?.minimum_levels_of_bets}
                                                    </span>
                                                </div>
                                                <div className='col-lg-12 mb-3 text-start'>
                                                    <span className='fw-bold fs-16'>
                                                        {table?.maximum_levels_of_bets}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}))}
                        </div>
                    </div>
                    </div>
                    <div class={`tab-pane w-100 ${searchTab === 'games' ? 'active show' : ''} fade`} id="games" aria-labelledby="pills-contact-directory-tab">
                        <div className='table-games w-60 mx-auto p-4'>
                            <div className='main-title'><span className='h3'>Games Info</span></div>
                            <div className='main-description'>
                            <p className='text-center w-75 fs-18'>
                                Take your pick, place your bets and enjoy the moment.
                            </p>
                        </div>
                        </div>
                        <div className='w-75 mx-auto'>
                        {/* <div className='row my-2'>
                            {games && games?.map((data,index) => (
                                    <div key={index} className='col-lg-4  col-sm-6 col-xxl-3 my-4'>
                                    <div className='card-table-games picture-wrapper padding-top-80'>
                                        <div className='text-center top-title'>
                                            <span className='fw-bold text-prime text-uppercase'>{data?.title}</span>
                                        </div>
                                        <div className='text-center left-title'>
                                            <Link className='btn-3' to={data?.link}>Rules of the game</Link>
                                        </div>
                                        <div><img src={data?.image} alt="roulette" /></div>
                                    </div>
                                    <div className='card-table-info text-center py-4 w-100'>
                                            <div className='row w-75 ms-2'>
                                                <div className='col-lg-12 mb-3 text-start'>
                                                    <span className='fs-16'>
                                                        Minimum Levels Of Bets
                                                    </span>
                                                </div>
                                                <div className='col-lg-12 mb-3 text-start'>
                                                    <span className='fs-16'>
                                                        Maximum Levels Of Bets
                                                    </span>
                                                </div>
                                                <div className='col-lg-12 text-start'>
                                                    <span className='fs-16'>
                                                        Rooms
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='row w-50 ms-2'>
                                                <div className='col-lg-12 mb-3 text-start'>
                                                    <span className='fw-bold fs-16 '>
                                                        {data?.minimum_levels_of_bets}
                                                    </span>
                                                </div>
                                                <div className='col-lg-12 mb-3 text-start'>
                                                    <span className='fw-bold fs-16'>
                                                        {data?.maximum_levels_of_bets}
                                                    </span>
                                                </div>
                                                <div className='col-lg-12 text-start'>
                                                    <span className='fw-bold fs-16'>
                                                        {data?.category?.title}
                                                    </span>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            ))}

                         
                        </div> */}
                        {games && games?.map((data,index) =>  (
                            <>
                            
                            <h5 class="mb-2 text-prime fs-23 mt-4">{data?.title}</h5>
                            <div className="row " key={index}>
                                        <div class="col-md-6 col-sm-6 col-sm-12 position-relative" style={{height: '250px'}}>
                                            <div style={{ height: '250px '}}>
                                                <img className='object-cover br-10' src={data?.image} />
                                            </div>
                                            <Link className='rule' to={data?.link}>Rule Of The Game</Link>
                                        </div>
                                        
                                        <div className="col-md-6 col-sm-6 col-sm-12 single-room mt-4 mt-md-0">
                                            <div className='table-responsive'>
                                                <table className=" b-prime" style={{height: 'fit-content'}}>
                                                        <thead>
                                                            <tr>
                                                                <th className='text-prime fs-18 text-center bb-2-prime text-uppercase align-middle p-2'>Room</th>
                                                                <th className='text-prime fs-18 text-center bb-2-prime text-uppercase align-middle p-2'>minimum levels of bets</th>
                                                                <th className='text-prime fs-18 text-center bb-2-prime text-uppercase align-middle p-2'>maximum levels of bets</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {tableGames?.filter((detail) => detail?.game_id === data?.id)?.map((game)=> (
                                                                <tr>
                                                                <td className='text-second fs-18 text-center bb-prime bt-prime text-uppercase align-middle p-3'>{game?.room?.title}</td>
                                                                <td className='text-second fs-18 text-center bb-prime bt-prime text-uppercase align-middle p-3'>{game?.minimum_levels_of_bets}</td>
                                                                <td className='text-second fs-18 text-center bb-prime bt-prime text-uppercase align-middle p-3'>{game?.maximum_levels_of_bets}</td>
                                                            </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>   
                                    </div> 
                                 </>   
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
</>
  )
}

export default TableGames